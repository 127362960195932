import React, { useEffect } from "react";
import './VerifiedByGS1.css';

const VerifiedByGS1 = () => {
    useEffect(() => {
        // Check if the Google Tag script already exists to prevent duplicates
        if (!document.querySelector('script[src="https://www.googletagmanager.com/gtag/js?id=G-3J0349LVCC"]')) {
          // Load the Google Tag script
          const script1 = document.createElement("script");
          script1.async = true;
          script1.src = "https://www.googletagmanager.com/gtag/js?id=G-3J0349LVCC";
          document.head.appendChild(script1);
    
          // Add the Google Tag initialization script
          const script2 = document.createElement("script");
          script2.innerHTML = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-3J0349LVCC');
          `;
          document.head.appendChild(script2);
        }
      }, []);
    return (
        <div className="verified-by-gs1-container">
            <div className="Agriculture3-banner">
                <div className="banner2-content">
                    <h1>Verified By GS1</h1>
                </div>
            </div>
            <div className="iframe-container">
                <iframe
                    src="https://www.gs1.org/services/verified-by-gs1/iframe"
                    title="Verified Search"
                    width="100%"
                    height="600"
                    frameBorder="0"
                    allowFullScreen
                ></iframe>
            </div>
        </div>
    );
};

export default VerifiedByGS1;